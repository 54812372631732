/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const appbar: Record<string, TAppbar> = {
  'en-AU': {
    appbar: {
      cta: 'Book your next adventure',
      ctaSimple: 'Book',
      talkToUs: 'Call: +61 1300159127',
      continueBooking: 'Continue',
      bookingInProgress: 'Booking in progress',
      bookingsInProgress: {
        title: 'Bookings in progress',
        statusInProgressMessage: 'Booking held for 30 minutes – make sure you get the best possible price – call +61 1300159127',
        statusExpiringMessage: 'Don’t miss out. Finish your booking today!',
        statusExpiredMessage: 'Click here to start a new booking.',
        statusExpiredTitle: 'Booking expired',
        statusExpiringTitle: 'Booking about to expire',
        unfinishedTitle: 'Unfinished booking',
        unfinishedMessage: 'It\'s not too late. Restart where you left off.'
      },
      searchOurCruises: 'Search our cruises',
      hrgGroupBanner: 'A {LOGO} Company',
      backTo: 'Back to ',
      contactUs: 'Contact +61 1300159127',
      phoneNumber: '+61 1300159127',
      siteSearch: 'Site search',
      findCruises: 'Find cruises, articles and more'
    }
  },
  'en-GB': {
    appbar: {
      cta: 'Book your next adventure',
      ctaSimple: 'Book',
      talkToUs: 'Call: +44 2036959973',
      continueBooking: 'Continue',
      bookingInProgress: 'Booking in progress',
      bookingsInProgress: {
        title: 'Bookings in progress',
        statusInProgressMessage: 'Your booking progress is saved for up to 30 minutes',
        statusExpiringMessage: 'Don’t miss out. Finish your booking today!',
        statusExpiredMessage: 'Click here to start a new booking.',
        statusExpiredTitle: 'Booking expired',
        statusExpiringTitle: 'Booking about to expire',
        unfinishedTitle: 'Unfinished booking',
        unfinishedMessage: 'It\'s not too late. Restart where you left off.'
      },
      searchOurCruises: 'Search our cruises',
      hrgGroupBanner: 'A {LOGO} Company',
      backTo: 'Back to ',
      contactUs: 'Contact +44 2036959973',
      phoneNumber: '+44 2036959973',
      siteSearch: 'Site search',
      findCruises: 'Find cruises, articles and more'
    }
  },
  'en-US': {
    appbar: {
      cta: 'Book your next adventure',
      ctaSimple: 'Book',
      talkToUs: 'Call: +18 885689793',
      continueBooking: 'Continue',
      bookingInProgress: 'Booking in progress',
      bookingsInProgress: {
        title: 'Bookings in progress',
        statusInProgressMessage: 'Your booking progress is saved for up to 30 minutes',
        statusExpiringMessage: 'Don’t miss out. Finish your booking today!',
        statusExpiredMessage: 'Click here to start a new booking.',
        statusExpiredTitle: 'Booking expired',
        statusExpiringTitle: 'Booking about to expire',
        unfinishedTitle: 'Unfinished booking',
        unfinishedMessage: 'It\'s not too late. Restart where you left off.'
      },
      searchOurCruises: 'Search our cruises',
      hrgGroupBanner: 'A {LOGO} Company',
      backTo: 'Back to ',
      contactUs: 'Contact +18 885689793',
      phoneNumber: '+18 885689793',
      siteSearch: 'Site search',
      findCruises: 'Find cruises, articles and more'
    }
  },
  'de-DE': {
    appbar: {
      cta: 'Buchen Sie Ihr nächstes Abenteuer',
      ctaSimple: 'Buchen',
      talkToUs: 'Tel.: +49 4087405609',
      continueBooking: 'Weiter',
      bookingInProgress: 'Buchung in Bearbeitung',
      bookingsInProgress: {
        title: 'Laufende Buchungen',
        statusInProgressMessage: 'Ihr Buchungsfortschritt wird für bis zu 30 Minuten gespeichert',
        statusExpiringMessage: 'Lassen Sie sich nichts entgehen. Schließen Sie Ihre Buchung noch heute ab!',
        statusExpiredMessage: 'Klicken Sie hier, um mit der Erstellung einer neuen Buchung zu beginnen.',
        statusExpiredTitle: 'Buchung abgelaufen',
        statusExpiringTitle: 'Buchung läuft bald ab',
        unfinishedTitle: 'Nicht abgeschlossene Buchung',
        unfinishedMessage: 'Es ist noch nicht zu spät. Fahren Sie da fort, wo Sie aufgehört haben.'
      },
      searchOurCruises: 'Expeditions-Seereise suchen',
      hrgGroupBanner: 'Ein Unternehmen der {LOGO}',
      backTo: 'Zurück zu',
      contactUs: 'Kontakt +49 4087405609',
      phoneNumber: '+49 4087405609',
      siteSearch: 'Suche vor Ort',
      findCruises: 'Finden Sie Kreuzfahrten, Artikel und mehr'
    }
  },
  'gsw-CH': {
    appbar: {
      cta: 'Buchen Sie Ihr nächstes Abenteuer',
      ctaSimple: 'Buchen',
      talkToUs: 'Tel.: +41 800 563 059',
      continueBooking: 'Weiter',
      bookingInProgress: 'Buchung in Bearbeitung',
      bookingsInProgress: {
        title: 'Laufende Buchungen',
        statusInProgressMessage: 'Ihr Buchungsfortschritt wird für bis zu 30 Minuten gespeichert',
        statusExpiringMessage: 'Lassen Sie sich nichts entgehen. Schließen Sie Ihre Buchung noch heute ab!',
        statusExpiredMessage: 'Klicken Sie hier, um mit der Erstellung einer neuen Buchung zu beginnen.',
        statusExpiredTitle: 'Buchung abgelaufen',
        statusExpiringTitle: 'Buchung läuft bald ab',
        unfinishedTitle: 'Nicht abgeschlossene Buchung',
        unfinishedMessage: 'Es ist noch nicht zu spät. Fahren Sie da fort, wo Sie aufgehört haben.'
      },
      searchOurCruises: 'Expeditions-Seereise suchen',
      hrgGroupBanner: 'Ein Unternehmen der {LOGO}',
      backTo: 'Zurück zu',
      contactUs: 'Kontakt +41 800 563 059',
      phoneNumber: '+41 800 563 059',
      siteSearch: 'Suche vor Ort',
      findCruises: 'Finden Sie Kreuzfahrten, Artikel und mehr'
    }
  },
  'fr-FR': {
    appbar: {
      cta: 'Réservez votre prochaine aventure',
      ctaSimple: 'Réserver',
      talkToUs: 'Tél.: +33 182880759',
      continueBooking: 'Continuer',
      bookingInProgress: 'Réservation en cours',
      bookingsInProgress: {
        title: 'Réservations en cours',
        statusInProgressMessage: 'Votre réservation en cours est sauvegardée pendant 30 minutes maximum',
        statusExpiringMessage: 'Ne passez pas à côté. Terminez votre réservation dès aujourd’hui !',
        statusExpiredMessage: 'Cliquez ici pour démarrer une nouvelle réservation.',
        statusExpiredTitle: 'Réservation expirée',
        statusExpiringTitle: 'Réservation sur le point d’expirer',
        unfinishedTitle: 'Réservation inachevée',
        unfinishedMessage: 'Il n’est pas trop tard. Reprenez là où vous en étiez.'
      },
      searchOurCruises: 'Explorer nos croisières',
      hrgGroupBanner: 'Une société {LOGO}',
      backTo: 'Retour á',
      contactUs: 'Contact +33 182880759',
      phoneNumber: '+33 182880759',
      siteSearch: 'Recherche sur le site',
      findCruises: 'Trouver des croisières, des articles et plus encore'
    }
  },
  'da-DK': {
    appbar: {
      cta: 'Bestil dit næste eventyr',
      ctaSimple: 'Bestil',
      talkToUs: 'Tlf.: +45 89875743',
      continueBooking: 'Fortsæt',
      bookingInProgress: 'Bestilling i gang',
      bookingsInProgress: {
        title: 'Bestilling i gang',
        statusInProgressMessage: 'Din bestilling gemmes i op til 30 minutter',
        statusExpiringMessage: 'Gå ikke glip af eventyret. Bestil din rejse i dag!',
        statusExpiredMessage: 'Klik her for starte en ny bestilling.',
        statusExpiredTitle: 'Bestillingen er udløbet',
        statusExpiringTitle: 'Bestillingen er tæt på at udløbe',
        unfinishedTitle: 'Færdiggør din booking',
        unfinishedMessage: 'Det er ikke for sent. Fortsæt, hvor du slap.'
      },
      searchOurCruises: 'Søg krydstogter',
      hrgGroupBanner: 'Et selskab i {LOGO}',
      backTo: 'Tilbage til ',
      contactUs: 'Kontakt +45 89875743',
      phoneNumber: '+45 89875743',
      siteSearch: 'Søgning på webstedet',
      findCruises: 'Find krydstogter, artikler og meget mere'
    }
  },
  'sv-SE': {
    appbar: {
      cta: 'Boka ditt nästa äventyr',
      ctaSimple: 'Boka',
      talkToUs: 'Tlf: +46 844687060',
      continueBooking: 'Fortsätt',
      bookingInProgress: 'Bokning pågår',
      bookingsInProgress: {
        title: 'Pågående bokningar',
        statusInProgressMessage: 'Ditt bokningsförlopp sparas i upp till 30 minuter.',
        statusExpiringMessage: 'Gå inte miste om äventyret. Slutför din bokning i dag!',
        statusExpiredMessage: 'Klicka här för att starta en ny bokning.',
        statusExpiredTitle: 'Bokningen har löpt ut',
        statusExpiringTitle: 'Bokningen håller på att löpa ut',
        unfinishedTitle: 'Oavslutad bokning',
        unfinishedMessage: 'Det är inte för sent. Fortsätt där du slutade.'
      },
      searchOurCruises: 'Sök våra kryssningar',
      hrgGroupBanner: 'Ett {LOGO} företag',
      backTo: 'Tillbaka till ',
      contactUs: 'Kontakt +46 844687060',
      phoneNumber: '+46 844687060',
      siteSearch: 'Sök på webbplatsen',
      findCruises: 'Hitta kryssningar, artiklar och mycket mer'
    }
  },
  'nb-NO': {
    appbar: {
      cta: 'Book ditt neste eventyr',
      ctaSimple: 'Bestill',
      talkToUs: 'Tlf: +47 75987787',
      continueBooking: 'Fortsett',
      bookingInProgress: 'Bestilling pågår',
      bookingsInProgress: {
        title: 'Pågående bestillinger',
        statusInProgressMessage: 'Den pågående bestillingen din lagres opptil 30 minutter',
        statusExpiringMessage: 'Ikke gå glipp av dette. Fullfør bestillingen din i dag!',
        statusExpiredMessage: 'Klikk her for å starte en ny booking.',
        statusExpiredTitle: 'Bookingen er utløpt',
        statusExpiringTitle: 'Bookingen er i ferd med å utløpe',
        unfinishedTitle: 'Uferdig booking',
        unfinishedMessage: 'Det er ikke for sent. Ta opp tråden igjen.'
      },
      searchOurCruises: 'Søk ekspedisjonscruise',
      hrgGroupBanner: 'Et {LOGO} selskap',
      backTo: 'Tilbake til ',
      contactUs: 'Kontakt +47 75987787',
      phoneNumber: '+47 75987787',
      siteSearch: 'Søk på nettstedet',
      findCruises: 'Finn cruise, artikler og mer'
    }
  },
  'en': {
    appbar: {
      cta: 'Book your next adventure',
      ctaSimple: 'Book',
      talkToUs: 'Call: +44 2081311464',
      continueBooking: 'Continue',
      bookingInProgress: 'Booking in progress',
      bookingsInProgress: {
        title: 'Bookings in progress',
        statusInProgressMessage: 'Your booking progress is saved for up to 30 minutes',
        statusExpiringMessage: 'Don’t miss out. Finish your booking today!',
        statusExpiredMessage: 'Click here to start a new booking.',
        statusExpiredTitle: 'Booking expired',
        statusExpiringTitle: 'Booking about to expire',
        unfinishedTitle: 'Unfinished booking',
        unfinishedMessage: 'It\'s not too late. Restart where you left off.'
      },
      searchOurCruises: 'Search our cruises',
      hrgGroupBanner: 'A {LOGO} Company',
      backTo: 'Back to ',
      contactUs: 'Contact +44 2081311464',
      phoneNumber: '+44 2081311464',
      siteSearch: 'Site search',
      findCruises: 'Find cruises, articles and more'
    }
  }
}

export type TAppbar = {
  appbar: {
    cta: string;
    ctaSimple: string;
    talkToUs: string;
    continueBooking: string;
    bookingInProgress: string;
    bookingsInProgress: {
      title: string;
      statusInProgressMessage: string;
      statusExpiringMessage: string;
      statusExpiredMessage: string;
      statusExpiredTitle: string;
      statusExpiringTitle: string;
      unfinishedTitle: string;
      unfinishedMessage: string;
    };
    searchOurCruises: string;
    hrgGroupBanner: string;
    backTo: string;
    contactUs: string;
    phoneNumber: string;
    siteSearch: string;
    findCruises: string;
  };
}
