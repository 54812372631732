import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { useTranslate } from '@hooks';
import { common } from '@microcopies';

const Hamburger = ({
  active,
  toggleMenu
}: {
  active?: boolean;
  toggleMenu: (arg: boolean) => void;
}) => {
  const [open, setOpen] = useState(false);
  const translate = useTranslate(common, (x) => x.common);

  const toggleOpen = (value: boolean) => {
    toggleMenu(value);
    setOpen(value);
  };

  useEffect(() => {
    if (!active) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [active]);

  const commonClasses =
    'absolute block w-full h-[1px] bg-black left-0 rotate-0 transition origin-[10%_50%]';

  return (
    <button
      className="w-[14px] h-[8px] relative rotate-0 transition cursor-pointer"
      aria-haspopup="menu"
      onClick={() => toggleOpen(!open)}
      aria-expanded={open}
      aria-controls="mobileNav"
      data-testid={open ? 'menu-drawer-close' : 'menu-drawer-open'}
    >
      <span
        className={clsx(commonClasses, 'top-0', {
          'rotate-45  ': open
        })}
      ></span>
      <span
        className={clsx(commonClasses, 'top-2', {
          '-rotate-45 ': open
        })}
      ></span>
      <span className="sr-only"> {translate((x) => x.openMenu)}</span>
    </button>
  );
};

export default Hamburger;
