import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import NextLink from 'next/link';
import { Button, Icon } from '@hurtigruten/design-system-components';

import { ContentfulImage } from '@atoms';
import { HeaderRibbon } from '@content/gql/graphql';
import { useLocale, useTranslate } from '@hooks';
import { MegaNavData } from '@content/queries/contentApi/getMegaNav';
import { useSiteData } from '@context/DataContext';
import { footer } from '@microcopies';

import ContactUsContent from './ContactUsContent';
import { IconHeader, CountriesList } from './CountrySelectorDesktop';
import { SectionNames } from './constants';
import { hideNorwayForNordics } from './MegaNavPanelDesktop';

const MegaNavPanelMobile = ({
  sections,
  onClick,
  open,
  activeTab,
  previousTab,
  toggleMenu,
  ribbonLink,
  navOriginalPosition
}: {
  sections: MegaNavData['sections'];
  onClick: (arg: string) => void;
  open: boolean;
  activeTab: string | null;
  previousTab?: string | null;
  toggleMenu: (arg: boolean) => void;
  ribbonLink?: HeaderRibbon['link'];
  navOriginalPosition: boolean;
}) => {
  const locale = useLocale();
  const { data: siteData } = useSiteData();
  const { footer: footerData } = siteData;
  const translate = useTranslate(footer, (x) => x.footer);
  const mainMenuRefs = useRef<HTMLButtonElement[]>([]);
  const subMenuRefs = useRef<HTMLButtonElement[]>([]);
  const [usedMouseClick, setUsedMouseClick] = useState(false);

  const { newsletterPromotion } = footerData ?? {
    newsletterPromotion: null
  };

  const mainMenuSections = sections?.filter(
    (s) => s.navControl !== SectionNames.ContactUs
  );

  const attachRef = (index: number) => (element: HTMLButtonElement) => {
    mainMenuRefs.current[index] = element;
  };

  const attachSubMenuRef = (index: number) => (element: HTMLButtonElement) => {
    subMenuRefs.current[index] = element;
  };

  const returningToMain = (index: number) => {
    if (!usedMouseClick) {
      mainMenuRefs.current[index].focus();
    }
    setUsedMouseClick(false);
  };

  const visitSection = (index: number) => {
    if (!usedMouseClick) {
      subMenuRefs.current[index].focus();
    }
  };

  const animateClass = (tab: string) =>
    clsx(
      ` block mb-3 opacity-0 duration-[250ms] transition ease-in-out-smooth relative `,
      {
        'opacity-100 z-10': activeTab === tab
      }
    );

  const hoverClass = () =>
    clsx(
      'inline bg-gradient-to-r from-black to-black bg-leftOffBottom bg-no-repeat bg-[length:0%_1px] w-fit duration-200 hover:bg-[length:100%_1px] transition-bgsize'
    );

  return (
    <div
      data-testid="MegaNavPanelMobile"
      className={clsx(
        'max-h-0 overflow-hidden  w-full bg-white transition-all duration-300 ease-in-out-smooth z-100 relative',
        {
          'max-h-[calc(100vh_-_104px)] h-[100vh] bottom-0 duration-300':
            open && navOriginalPosition,
          'max-h-[calc(100vh_-_64px)] h-[100vh] bottom-0 duration-300':
            open && !navOriginalPosition
        }
      )}
      aria-hidden={open ? 'false' : 'true'}
    >
      {/* main nav links */}
      <div className="flex flex-col py-4 tablet:pr-5" id="mobileNav">
        {mainMenuSections?.map((s, i) => (
          <button
            key={i}
            className={clsx(
              'flex justify-between mx-4 my-4 duration-500 transition ease-in-out-smooth  opacity-0',
              {
                'opacity-100': open && activeTab === null
              }
            )}
            style={{
              transitionDelay: `${(i + 2) * 4}0ms`
            }}
            aria-expanded={activeTab === s.navControl}
            tabIndex={open && !activeTab ? 0 : -1}
            aria-controls={`submenu-section-${i}`}
            onMouseDown={() => setUsedMouseClick(true)}
            onClick={() => {
              onClick(s.navControl);
              visitSection(i);
            }}
            ref={attachRef(i)}
          >
            <span className="text-base font-normal">{s.sectionName}</span>
            <span>
              <Icon graphic="chevron-right-light" />
            </span>
          </button>
        ))}
        <button
          className={clsx(
            'flex justify-between mx-4 my-4 duration-500 transition ease-in-out-smooth  opacity-0',
            {
              'opacity-100': open && activeTab === null
            }
          )}
          style={{
            transitionDelay: `${7 * 4}0ms`
          }}
          tabIndex={open && !activeTab ? 0 : -1}
          ref={attachRef(mainMenuSections.length + 1)}
          onClick={() => {
            onClick(SectionNames.CountrySelect);
            visitSection(mainMenuSections.length + 1);
          }}
          aria-expanded={activeTab === SectionNames.CountrySelect}
          aria-controls={`submenu-section-${mainMenuSections.length + 1}`}
        >
          <span className="text-base font-normal">
            <IconHeader showTitle={true} />
          </span>
          <span>
            <Icon graphic="chevron-right-light" />
          </span>
        </button>
        {ribbonLink && (
          <a
            href={ribbonLink?.url}
            className={clsx(
              'flex justify-between mx-4 my-4 duration-500 transition ease-in-out-smooth  opacity-0',
              {
                'opacity-100': open && activeTab === null
              }
            )}
            tabIndex={open && !activeTab ? 0 : -1}
            style={{
              transitionDelay: `${8 * 4}0ms`
            }}
          >
            <span className="text-base font-normal">{ribbonLink?.label} </span>
            <span>
              <Icon graphic="external-link" />
            </span>
          </a>
        )}
      </div>

      {/* END main nav links */}
      {sections.map((tab, index) => {
        const filterLinks =
          tab.navControl === SectionNames.Destinations
            ? hideNorwayForNordics(locale, tab.links)
            : tab.links;
        return (
          <div
            key={index}
            id={`submenu-section-${index}`}
            className={clsx(
              'absolute top-0 opacity-0 left-0 w-full h-full flex -z-10 translate-x-8 duration-[250ms] transition ease-in-out-smooth ',
              {
                '!z-100 !translate-x-0 opacity-100':
                  activeTab === tab.navControl
              }
            )}
            aria-hidden={activeTab !== tab.navControl}
          >
            <div className="flex flex-col flex-1 w-full px-4 pt-8 pb-12 bg-white">
              {tab.navControl !== SectionNames.ContactUs ? (
                <button
                  className={`flex justify-start gap-2 !mb-2 text-base  text-hx-caviar items-center ${animateClass(
                    tab.navControl
                  )}`}
                  tabIndex={open && activeTab === tab.navControl ? 0 : -1}
                  aria-expanded={activeTab === tab.navControl}
                  ref={attachSubMenuRef(index)}
                  onClick={() => {
                    onClick(tab.navControl);
                    returningToMain(index);
                  }}
                >
                  <span>
                    <Icon graphic="chevron-left-light" />
                  </span>{' '}
                  {tab.sectionName}
                </button>
              ) : (
                <div
                  className={`block w-full text-base !mb-2 delay-[50ms]  ${animateClass(
                    tab.navControl
                  )}`}
                >
                  {tab.sectionName}
                </div>
              )}

              {tab.navControl === SectionNames.ContactUs && (
                <div className="flex flex-col flex-1 max-h-full overflow-y-scroll">
                  <ContactUsContent
                    animateClass={animateClass}
                    hoverClass={hoverClass()}
                    messageUsLinks={tab.links}
                    helpCentreLinks={tab.featuredLinks}
                    navControl={tab.navControl}
                    activeTab={activeTab}
                    contentActive={activeTab === SectionNames.ContactUs}
                    onClick={() => onClick(SectionNames.ContactUs)}
                  />
                </div>
              )}
              {tab.navControl !== SectionNames.ContactUs && (
                <div className="flex flex-col flex-1 max-h-full pt-8 pl-8 pr-8 overflow-y-scroll">
                  <div className={clsx('flex flex-col')}>
                    {/* regular  links */}
                    {filterLinks.map((a, i) => {
                      const delay = previousTab === tab.navControl ? 1 : 7;
                      return (
                        <div
                          key={i}
                          className={`text-sm ${animateClass(tab.navControl)}`}
                          style={{
                            transitionDelay: `${(i + 2) * delay}0ms`
                          }}
                        >
                          <NextLink
                            locale={locale}
                            href={a.url}
                            prefetch={false}
                          >
                            <a
                              key={a.id}
                              className={hoverClass()}
                              onClick={() => {
                                toggleMenu(false);
                              }}
                              data-testid={a.label}
                              tabIndex={
                                open && activeTab === tab.navControl ? 0 : -1
                              }
                            >
                              {a.label}
                            </a>
                          </NextLink>
                        </div>
                      );
                    })}
                  </div>

                  {/* featured links */}
                  {tab.featuredLinks && (
                    <div className="flex flex-col mt-4 mb-16">
                      {tab.featuredLinksHeading && (
                        <p
                          className={`block mb-2 text-2xs delay-[30ms] text-hx-neutrals-60 ${animateClass(
                            tab.navControl
                          )}`}
                        >
                          {tab.featuredLinksHeading}
                        </p>
                      )}
                      {tab.featuredLinks.map((a, i) => (
                        <div
                          key={i}
                          className={`text-sm ${animateClass(tab.navControl)}`}
                          style={{ transitionDelay: `${(i + 3) * 7}0ms` }}
                        >
                          <NextLink
                            locale={locale}
                            href={a.url}
                            prefetch={false}
                          >
                            <a
                              className={`block mb-4 text-sm ${hoverClass()}`}
                              tabIndex={
                                open && activeTab === tab.navControl ? 0 : -1
                              }
                              onClick={() => {
                                toggleMenu(false);
                              }}
                            >
                              {a.label}
                            </a>
                          </NextLink>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* spotlight/newsletter section */}
                  <div
                    className={`mt-auto ${
                      tab.spotlight?.isNewsletter ? ' w-full' : 'w-[207px]'
                    } `}
                  >
                    {tab.spotlight?.title && (
                      <p
                        className={`block w-full !mb-2  text-2xs delay-[450ms] text-hx-neutrals-60 ${animateClass(
                          tab.navControl
                        )}`}
                      >
                        {tab.spotlight.title}
                      </p>
                    )}

                    {tab.spotlight?.subtitle && tab.spotlight?.url && (
                      <div
                        className={`delay-500 text-sm ${animateClass(
                          tab.navControl
                        )}`}
                      >
                        <NextLink
                          locale={locale}
                          href={`${tab.spotlight?.url.url}`}
                          prefetch={false}
                        >
                          <a
                            className={`block w-full ${hoverClass()}`}
                            tabIndex={
                              open && activeTab === tab.navControl ? 0 : -1
                            }
                            onClick={() => {
                              toggleMenu(false);
                            }}
                          >
                            {tab.spotlight.subtitle}
                          </a>
                        </NextLink>
                      </div>
                    )}
                    {tab.spotlight?.subtitle && !tab.spotlight?.url && (
                      <div
                        className={`delay-500 text-sm ${animateClass(
                          tab.navControl
                        )}`}
                      >
                        <p className={`block w-full `}>
                          {tab.spotlight.subtitle}
                        </p>
                      </div>
                    )}
                    <div
                      className={clsx(
                        `w-[207px] overflow-hidden h-[56px] relative`,
                        {
                          'h-[116px] ': !tab.spotlight?.isNewsletter
                        }
                      )}
                    >
                      <div
                        className={clsx(
                          `w-full overflow-hidden h-full absolute opacity-0 transition-transform delay-[550ms] ease-in-out-smooth bg-white`,
                          {
                            'z-20  opacity-100': activeTab === tab.navControl
                          }
                        )}
                      >
                        {tab.spotlight?.isNewsletter &&
                          newsletterPromotion?.cta?.slug && (
                            <Button
                              appearance="primary"
                              fillMobileWidth={false}
                              href={`/${locale}${
                                newsletterPromotion?.cta?.slug || '/'
                              }`}
                              text={newsletterPromotion?.cta?.label}
                              tabIndex={
                                open && activeTab === tab.navControl ? 0 : -1
                              }
                              onClick={() => {
                                toggleMenu(false);
                              }}
                            />
                          )}
                        {tab.spotlight?.image &&
                          !tab.spotlight?.isNewsletter &&
                          tab.spotlight?.url && (
                            <NextLink
                              locale={locale}
                              href={`${tab.spotlight?.url.url}`}
                              prefetch={false}
                            >
                              <a
                                className={`block w-full pb-3 delay-[600ms] ${animateClass(
                                  tab.navControl
                                )}`}
                                tabIndex={
                                  open && activeTab === tab.navControl ? 0 : -1
                                }
                                onClick={() => {
                                  toggleMenu(false);
                                }}
                              >
                                <ContentfulImage
                                  alt={tab.spotlight.image?.alt}
                                  className={``}
                                  src={tab.spotlight.image?.url}
                                  width={207}
                                  objectFit="cover"
                                  height={116}
                                />
                              </a>
                            </NextLink>
                          )}
                        {tab.spotlight?.image &&
                          !tab.spotlight?.isNewsletter &&
                          !tab.spotlight?.url && (
                            <ContentfulImage
                              alt={tab.spotlight.image?.alt}
                              className={``}
                              src={tab.spotlight.image?.url}
                              width={207}
                              objectFit="cover"
                              height={116}
                            />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
      <div
        className={clsx(
          'absolute top-0 opacity-0 left-0 w-full h-full flex -z-10 duration-[250ms] transition ease-in-out-smooth ',
          {
            '!z-100 opacity-100': activeTab === SectionNames.CountrySelect
          }
        )}
        aria-hidden={activeTab !== SectionNames.CountrySelect}
      >
        <div className="flex flex-col flex-1 w-full px-4 pt-8 pb-12 bg-white">
          <button
            className={`flex justify-start gap-2 !mb-2 text-hx-caviar items-center ${animateClass(
              SectionNames.CountrySelect
            )}`}
            ref={attachSubMenuRef(mainMenuSections.length + 1)}
            id={`submenu-section-${mainMenuSections.length + 1}`}
            aria-expanded={activeTab === SectionNames.CountrySelect}
            tabIndex={activeTab === SectionNames.CountrySelect ? 0 : -1}
            onClick={() => {
              onClick(SectionNames.CountrySelect);
              returningToMain(mainMenuSections.length + 1);
            }}
          >
            <span>
              <Icon graphic="chevron-left-light" />
            </span>
            {translate((x) => x.changeCountry)}
          </button>

          <div className="flex flex-col flex-1 max-h-full pt-8 pl-8 pr-8 overflow-y-scroll">
            <CountriesList
              activeTab={activeTab === SectionNames.CountrySelect}
              onClick={() => toggleMenu(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MegaNavPanelMobile;
