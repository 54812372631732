module.exports = {
  breakpoints: {
    mobile: '320px',
    'mobile-large': '375px',
    mobileLandscape: '667px',
    tablet: '768px',
    tabletLandscape: '1024px',
    laptop: '1170px',
    desktop: '1441px'
  }
};
