import { useState, useEffect } from 'react';
import { signIn, signOut, useSession } from 'next-auth/react';
import NextLink from 'next/link';
import { Icon } from '@hurtigruten/design-system-components';
import clsx from 'clsx';

import { useTranslate, useLocale, useMediaQuery } from '@hooks';
import { common } from '@microcopies';
import { breakpoints } from '@src/utils';
import { mapLocaleToMyAccountUri } from '@src/utils/mappers/uriMappers';
import { mapLocaleToISOLanguageCode } from '@src/utils/locale/mappers';

import NavButton from './NavButton';

const hoverClass = () =>
  clsx(
    'inline bg-gradient-to-r from-black to-black bg-leftOffBottom bg-no-repeat bg-[length:0%_1px] w-fit duration-200 hover:bg-[length:100%_1px] transition-bgsize'
  );

const animateClass = (activeTab: boolean) =>
  clsx(
    ` block mb-3 text-sm opacity-0 duration-[250ms] transition ease-in-out-smooth relative `,
    {
      'opacity-100 ': activeTab
    }
  );

const dropDownClass = (open: boolean) =>
  clsx(
    'h-0 overflow-hidden w-[250px] absolute bg-white transition-all duration-100 ease-in-out-smooth z-100 top-full right-0',
    {
      'h-[105px]': open
    }
  );

const MyAccountDropdown = ({
  activeTab,
  menuAction,
  setAccountActive,
  closeMobileMenu,
  tabIndex,
  parentRef
}: {
  activeTab: boolean;
  menuAction: boolean;
  setAccountActive: () => void;
  closeMobileMenu: () => void;
  tabIndex: number;
  parentRef: (element: HTMLButtonElement) => void;
}) => {
  const locale = useLocale();
  const { data: session } = useSession();
  const [open, setOpen] = useState(false);
  const [isTrimmed, setIsTrimmed] = useState(false);
  const [name, setName] = useState<string>();
  const isDesktop = useMediaQuery(breakpoints.tabletLandscape);
  const translateCommon = useTranslate(common, (x) => x.common);

  useEffect(() => {
    const getFirstName = session && session.user?.name?.split(' ')[0];
    const nameLength = getFirstName?.length;
    if (nameLength) {
      if (nameLength > 12) {
        setIsTrimmed(true);
        const trimLength = nameLength - 12;
        const trimmed = getFirstName.slice(0, -trimLength);
        setName(trimmed);
      } else {
        setName(getFirstName);
      }
    }
  }, [session]);

  const clickAction = () => {
    if (!session) {
      void signIn(
        'azureb2c',
        {
          callbackUrl: `/${locale}/`
        },
        {
          ui_locales: mapLocaleToISOLanguageCode(locale)
        }
      );
    } else {
      setAccountActive();
      if (open) {
        setOpen(false);
      } else {
        closeMobileMenu();
        setOpen(true);
      }
    }
  };

  useEffect(() => {
    if (!activeTab) {
      setOpen(false);
    }
  }, [activeTab]);

  return (
    <>
      <NavButton
        active={activeTab}
        open={menuAction}
        onClick={() => clickAction()}
        data-testid="my-account-loginbutton"
        tabIndex={tabIndex}
        ref={parentRef}
      >
        <span className="flex text-sm font-normal">
          <span className={isDesktop ? 'mt-[1px] ' : 'sr-only'}>
            {session ? (
              <span>
                {name}
                {isTrimmed && <>...</>}
              </span>
            ) : (
              translateCommon((x) => x.login)
            )}
          </span>
          <Icon graphic="account-light" />
        </span>
      </NavButton>
      <div data-testid="MegaNavAccount" className={dropDownClass(open)}>
        <div className="px-6 py-5">
          <div
            className={animateClass(activeTab)}
            style={{
              transitionDelay: `150ms`
            }}
          >
            <NextLink
              locale={locale}
              href={`/${mapLocaleToMyAccountUri(locale)}`}
              prefetch={false}
            >
              <a
                className={`flex gap-2`}
                data-testid="myAccount-homepage"
                tabIndex={open ? 0 : -1}
                onClick={() => clickAction()}
              >
                <Icon graphic="hx" />
                <span className={hoverClass()}>
                  {translateCommon((x) => x.myaccount)}
                </span>
              </a>
            </NextLink>
          </div>
          <div
            className={animateClass(activeTab)}
            style={{
              transitionDelay: `200ms`
            }}
          >
            <button
              className={`flex gap-2 `}
              data-testid="logout-button"
              tabIndex={open ? 0 : -1}
              onClick={() => {
                clickAction();
                void signOut({
                  callbackUrl: `/${locale}/`
                });
              }}
            >
              <Icon graphic="signout" />
              <span className={hoverClass()}>
                {translateCommon((x) => x.logout)}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAccountDropdown;
