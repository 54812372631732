import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import clsx from 'clsx';

import { GlobalInfoBanner } from '@content/models';
import { Icon } from '@atoms';
import {
  ArrowRightLine,
  InformationLine,
  AlarmLine,
  AlarmWarningLine,
  StarLine
} from '@components/icons/System';
import { FlashlightLine } from '@components/icons/Weather';
import { useTranslate, useBannerInformation, useLocale } from '@hooks';
import { common } from '@microcopies';
import { isFeatureEnabled } from '@src/utils';

const getBannerStyle = (style: string | undefined) => {
  let styles = 'bg-white';
  let icon = InformationLine;
  switch (style) {
    case 'critical':
      styles = 'bg-light-hrg-red';
      break;
    case 'warning':
      styles = 'bg-flare-yellow';
      icon = AlarmWarningLine;
      break;
    case 'offer':
      styles = 'bg-light-green-secondary';
      icon = AlarmLine;
      break;
    case 'campaign':
      styles = 'bg-snow-blue';
      icon = FlashlightLine;
      break;
    case 'upgrade':
      styles = 'bg-black text-white';
      icon = StarLine;
      break;
    default:
  }
  return { styles, icon };
};

const DefaultBanner = ({ data }: { data: GlobalInfoBanner }) => {
  const locale = useLocale();
  const translate = useTranslate(common, (x) => x.common);

  const { storedData } = useBannerInformation();
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    setIsHidden(Boolean(storedData));
  }, [storedData]);

  if (isHidden || data === null) return null;

  if (!data?.show) return null;

  const bannerStyle = getBannerStyle(data?.style ?? undefined);

  return (
    <aside
      aria-label="Information banner"
      className={clsx('relative px-6 py-3 ui-text flex h-[52px]', {
        [bannerStyle.styles || '']: bannerStyle.styles
      })}
    >
      <div className="flex items-center justify-between w-full gap-3">
        <div className="flex tablet:sticky">
          <Icon icon={bannerStyle.icon} />
        </div>
        <NextLink href={data?.slug ?? ''} locale={locale} shallow passHref>
          <div
            className="flex items-center justify-start flex-1 tablet:justify-center"
            role="button"
          >
            <div className="flex flex-row gap-1 tablet:gap-6">
              <p className="mx-auto">{data?.title}</p>
              <div className="flex tablet:gap-2">
                <span className="hidden underline tablet:flex">
                  {translate((x) => x.readMore)}
                </span>
                <Icon icon={ArrowRightLine} />
              </div>
            </div>
          </div>
        </NextLink>
      </div>
    </aside>
  );
};

const InfoBanner = ({
  data
}: {
  isSiteSwitcher?: boolean;
  data: GlobalInfoBanner;
}) => {
  if (isFeatureEnabled('showInfoBanner')) {
    return <DefaultBanner data={data} />;
  }
  return null;
};

export default InfoBanner;
